import { useNavigate } from '@remix-run/react';
import { ComponentType, FC, useEffect, useState } from 'react';
import { getTokens } from '~/utils/localStorage';
import { LoadingDialog } from './loading-dialog';
import { ClientOnly } from './client-only';

export const withAuthentication = <P extends object>(
  Component: ComponentType<P>,
) => {
  const WithAuthentication: FC<P> = (props) => {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
      const { accessToken } = getTokens();
      const isAuthenticated = Boolean(accessToken);
      if (!isAuthenticated) navigate('/login');
      setIsAuthenticated(isAuthenticated);
    }, [navigate]);

    return isAuthenticated ? (
      <Component {...props} />
    ) : (
      <ClientOnly fallback={null}>{() => <LoadingDialog open />}</ClientOnly>
    );
  };

  return WithAuthentication;
};
